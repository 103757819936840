import React, { useState } from 'react'
import { CSSTransition } from 'react-transition-group'

import Icon from '../Icon/icon'

import logoIcon from '../../images/logo-icon.svg';

import styles from '../../styles/highlights.module.scss'
import '../../styles/effects.css'
 
function HighlightCard ({children, fixed, customClass, showCard}) {
    const [isOpen, setIsOpen] = useState(fixed)
    const [iconClass, setIconClass] = useState(styles.iconContainer)
    let show = showCard === undefined || showCard;
    
    function toggleCard () {
        if (fixed) {
            return
        }
        setIsOpen(!isOpen)
    }

    function fixIconPosition() {
        setIconClass(styles.hideIcon)
    }

    function removeIconClass() {
        setIconClass('')
    }
    
    return show ? (
        <div
        onMouseEnter={() => toggleCard()}
        onMouseLeave={() => toggleCard()}
        className={`${styles.highlightCard} ${styles.cardClass} ${customClass}`}
        >
            {!isOpen ? <Icon style={iconClass} imageSrc={logoIcon}/> : null}
            <CSSTransition
                in={isOpen}
                mountOnEnter
                unmountOnExit
                timeout={200}
                classNames="node"
                onExiting={() => fixIconPosition()}
                onExited={() => removeIconClass()}
            >
                {children}
            </CSSTransition>
        </div>
    ) : null
}

export default HighlightCard
