import React from 'react'

import styles from '../../styles/pages.module.scss'

import lamp from '../../images/lampada2.gif'

function Lamp () {
    return (
        <section className={`${styles.pageContainer} ${styles.section2}`}>
            <div className={`${styles.pageContent} ${styles.section2Content}`}>
                <div className={styles.pageContentWrapper}>
                    <div className={`${styles.pageLeft} ${styles.section2Left}`}>
                        <h4>
                            Experiência 
                            <strong>transforma.</strong> 
                            Informação 
                            <strong>aproxima.</strong> 
                            <span>Propósito <strong>guia.</strong></span>
                        </h4>
                    </div>
                    <div className={styles.mainImage}>
                        <img src={lamp} className={styles.lamp} alt="Pessoalize - Propósito"></img>
                    </div>
                    <div className={`${styles.pageRight} ${styles.section2Right}`}>
                        <h4><strong>E a gente trabalha por propósito.</strong></h4>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Lamp
