import React, { useState, useRef, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'

import '../../styles/pages.module.scss'
import  '../../styles/carousel.scss'
import 'react-multi-carousel/lib/styles.css';

function ResultCard ({client, fixed, current, identifier, carouselItem }) {
    const el = useRef(null)
    const cardRef = useRef(null)
    const imgRef = useRef(null)
    const [isOpen, setIsOpen] = useState(fixed)
    const [itemClass, setItemClass] =  useState('carousel')
    let brandLogo = client.frontmatter.featuredImage.publicURL

    useEffect(() => {        
        function handleItemClass () {
            if (isActiveOnCarousel()) {
                return 'active'
            }
    
            return isPreviousItem() ? 'previous' : 'next';
        }
        
        if (isActiveOnCarousel()) {
            current(identifier)
        } else {
            setIsOpen(false)
        }

        setItemClass(handleItemClass());
    }, [carouselItem, current, identifier])

    function isPreviousItem () {
        const nextSiblingClasses = el.current?.parentElement?.nextElementSibling?.classList
        return Array.from(nextSiblingClasses || '').includes('react-multi-carousel-item--active');
    }

    function isActiveOnCarousel () {
        const elementClasses = Array.from(el.current?.parentElement?.classList)
        return elementClasses.includes('react-multi-carousel-item--active')
    }
    
    function toggleCard (state) {
        if (fixed || !isActiveOnCarousel()) {
            return
        }
        setIsOpen(state)
    }

    function fixTransitions (attribute) {
        cardRef.current.style.display = attribute
    }

    return (
        <div
            ref={el}
            // onMouseEnter={() => toggleCard(true)}
            // onMouseLeave={() => toggleCard(false)}
            className={itemClass}
        >
            <CSSTransition
                in={!isOpen}
                mountOnEnter
                unmountOnExit
                timeout={500}
                classNames="node"
                onExiting={() => fixTransitions('none')}
            >
                <img ref={imgRef} src={brandLogo} alt='customer-logo' />
            </CSSTransition>
            {/* <CSSTransition
                in={isOpen}
                mountOnEnter
                unmountOnExit
                timeout={500}
                classNames="node"
                onEntered={() => fixTransitions('block')}
                onExiting={() => fixTransitions('none')}
            >
                <div ref={cardRef} dangerouslySetInnerHTML={{__html: client.html}}/>
            </CSSTransition> */}
        </div>
    )
}

export default ResultCard
