import { useEffect, useState } from 'react'
import { throttle } from '../utils/utils'

export default function useComponentVisible (ref, callback) {
    const [visible, setVisible ] = useState(false)

    function isVisible(rect) {
        return (window.screen.height >= rect.bottom &&
        window.screen.width >= rect.right &&
        rect.top >= 0 &&
        rect.left >= 0)
    }
    
    function componentVisible () {
        if (isVisible(ref.current?.getBoundingClientRect()) && !visible) {
            callback()
            setVisible(true)
        }
    }

    useEffect(() => {
        document.addEventListener('scroll', throttle(componentVisible, 100), {passive: true})
        return () => document.removeEventListener('scroll', throttle(componentVisible, 100))
    })
}