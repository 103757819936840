import React, { useState, useRef, useEffect } from 'react'
import { useStaticQuery } from "gatsby"
import SliderCarousel from '../SliderCarousel/sliderCarousel';

import ResultCard from './resultCard'

import roi from '../../images/roi-icon.svg'
import leads from '../../images/leads-icon.svg'
import data from '../../images/data-icon.svg'
import technology from '../../images/technology-icon.svg'

import styles from '../../styles/pages.module.scss'
import '../../styles/carousel.scss'



function Results() {
    let carousel = useRef(null);
    const [activeItem, setActiveItem] = useState(0)
    const [clientData, setClientData] = useState(null)
    const [carouselItem, setCarouselItem] = useState(null)
    const { allMarkdownRemark: clients } = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        edges {
          node {
            html
            frontmatter {
              featuredImage {
                publicURL
              }
              roi
              id
              lead
              dataAnalised
              tech
            }
          }
        }
      }
    }
  `)

    useEffect(() => {
        function getClientData(id) {
            return clients.edges.find(node => node.node.frontmatter.id === id)
        }

        if (!carouselItem) {
            carousel.current.goToSlide(0)
        }

        const clientNode = getClientData(activeItem)
        setClientData(clientNode)
    }, [carouselItem, activeItem, clients])

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            partialVisibilityGutter: 0
        }
    };

    const CustomRightArrow = ({ onClick, ...rest }) => {
        return <button onClick={() => onClick()} className={styles.carouselArrowRight} tabIndex='0' />;
    };

    const CustomLeftArrow = ({ onClick, ...rest }) => {
        return <button onClick={() => onClick()} className={styles.carouselArrowLeft} tabIndex='0' />;
    };

    function getCurrent(current) {
        setActiveItem(current)
    }

    return (
        <section className={`${styles.pageContainer} ${styles.results}`}>
            <div className={styles.resultsWrapper}>
                <h3>Nossos Clientes</h3>
                {/*<div className={styles.carouselActive}></div> */}
                <SliderCarousel
                    centerMode={true}
                    responsive={responsive}
                    itemClass={'carouselItem'}
                    sliderClass={'carouselWrapper'}
                    containerClass={'carouselContainer'}
                    customRightArrow={<CustomRightArrow />}
                    customLeftArrow={<CustomLeftArrow />}
                    infinite
                    afterChange={(previousSlide, { currentSlide, onMove }) => {
                        setCarouselItem(currentSlide)
                    }}
                    transitionDuration={250}
                    fowardedRef={carousel}
                >
                    {clients.edges.map(({ node }, index) => (
                        <ResultCard current={getCurrent}
                            client={node}
                            identifier={node.frontmatter.id}
                            key={index}
                            carouselItem={carouselItem}
                        />
                    ))}
                </SliderCarousel>
                {false ?
                    (
                        <div className={styles.carouselResultsContainer}>
                            <div className={styles.carouselResultsWrapper}>
                                <div className={styles.carouselResultsItem}>
                                    <img src={roi} alt="ROI"></img>
                                    <span>{clientData.node.frontmatter.roi}</span>
                                    <p>ROI</p>
                                </div>
                                <div className={styles.carouselResultsItem}>
                                    <img src={leads} alt="Leads por mês"></img>
                                    <span>{clientData.node.frontmatter.lead}</span>
                                    <p>Leads por mês</p>
                                </div>
                                <div className={styles.carouselResultsItem}>
                                    <img src={data} alt="Dados analisados"></img>
                                    <span>{clientData.node.frontmatter.dataAnalised}</span>
                                    <p>Dados analisados</p>
                                </div>
                                <div className={styles.carouselResultsItem}>
                                    <img src={technology} alt="Tecnologias implantadas"></img>
                                    <span>{clientData.node.frontmatter.tech}</span>
                                    <p>Tecnologias implantadas</p>
                                </div>
                            </div>
                        </div>
                    ) : null}
                {/* <a href="/" target="_blank" className={styles.resultsButton}>Ver mais resultados</a> */}
            </div>
        </section>
    )
}

export default Results
