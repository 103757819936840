import { useEffect, useState } from 'react'
import { throttle, isMobile } from '../utils/utils'

export default function useParallax (callback, useMobile) {
    const [offset, setOffset] = useState(0)

    function scrollAction () {
        let offset = callback()
        setOffset(offset)
    }
    
    useEffect(() => {
        if (!useMobile && isMobile()) {
            return
        }

        window.addEventListener('scroll', throttle(scrollAction, 500), {passive: true})
        return () => window.removeEventListener('scroll', throttle(scrollAction, 500), {passive: true})
    })

    return offset
}