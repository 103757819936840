import React, { useContext } from 'react'
import { AppContext } from '../../../provider'

import HighlightBackground from './highlightBackground'
import HighlightCard from './highlightCard'
import HighlightsForm from './highlightsForm'
import useParallax from '../../hooks/useParallax'


import styles from '../../styles/highlights.module.scss'
import '../../styles/parallax.scss'

import logo from '../../images/logopurple.svg'
import text1 from '../../images/texts/text1.svg'
import text2 from '../../images/texts/text2.svg'
import text3 from '../../images/texts/text3.svg'
import text4 from '../../images/texts/text4.svg'
import text5 from '../../images/texts/text5.svg'
import text6 from '../../images/texts/text6.svg'

function HighlightDesktop() {
    const { isMobileDevice } = useContext(AppContext) || {}
    const offsetY = useParallax(handleScroll)

    function handleScroll() {
        if (!isMobileDevice) {
            return window.pageYOffset
        }
    }

    return (
        <section className="parallax">
            <HighlightBackground className='parallax-background' offset={offsetY} />
            <div className={styles.highlights}>
                <div className={styles.highlightsContainer}>
                    <div className={styles.highlightsWrapper}>
                        <HighlightCard customClass={styles.highlightsLogoCard} fixed={true}>
                            <div className={styles.highlightContent}>
                                <div className={`${styles.highlightCardContainer} ${styles.cardAqua}`}>
                                    <img src={logo} alt='card-logo' />
                                </div>
                            </div>
                        </HighlightCard>
                    </div>
                </div>
                <div className={styles.highlightsContainer}>
                    <div className={styles.highlightsWrapper}>
                        <HighlightCard>
                            <div className={styles.highlightContent}>
                                <div className={`${styles.highlightCardContainer} ${styles.cardBlue}`}>
                                    <img src={text1} alt='card-serviços' />
                                </div>
                            </div>
                        </HighlightCard>
                        <HighlightCard customClass={styles.highlightsMainInfo} fixed={true}>
                            <div className={styles.highlightContent}>
                                <div className={`${styles.highlightCardContainer} ${styles.cardPurple} ${styles.strategy}`}>
                                    <img src={text2} alt='card-serviços'></img>
                                </div>
                            </div>
                        </HighlightCard>
                        <HighlightCard>
                            <div className={styles.highlightContent} >
                                <div className={`${styles.highlightCardContainer} ${styles.cardPink}`}>
                                    <img src={text3} alt='card-serviços' />
                                </div>
                            </div>
                        </HighlightCard>
                    </div>
                </div>
                <div className={styles.highlightsContainer}>
                    <div className={styles.highlightsWrapper}>
                        <HighlightCard>
                            <div className={styles.highlightContent}>
                                <div className={`${styles.highlightCardContainer} ${styles.cardPurple} ${styles.libra}`}>
                                    <img src={text4} alt='card-acessibilidade' />
                                </div>
                            </div>
                        </HighlightCard>
                        <HighlightCard>
                            <div className={styles.highlightContent}>
                                <div className={`${styles.highlightCardContainer} ${styles.cardAqua}`}>
                                    <img src={text5} alt='card-serviços' />
                                </div>
                            </div>
                        </HighlightCard>
                        <HighlightCard>
                            <div className={styles.highlightContent}>
                                <div className={`${styles.highlightCardContainer} ${styles.cardPurple} ${styles.reclame}`}>
                                    <img src={text6} alt='card-reclameaqui' />
                                </div>
                            </div>
                        </HighlightCard>
                    </div>
                </div>
                {/* <div className={styles.highlightsAudioInputContainer}>
                    <div className={styles.highlightsAudioInputWrapper}>
                        <HighlightsForm />
                    </div>
                </div> */}
            </div>

        </section>
    )
}

export default HighlightDesktop
