import { AppContext } from '../../../provider'
import React, { useContext, useEffect } from 'react'

import HighlightsMobile from './highlightsMobile';
import HighlightDesktop from './highlightsDesktop';

import '../../styles/parallax.scss'

function Highlights() {
    const { isMobileDevice } = useContext(AppContext) || {}
    useEffect(() => {}, [isMobileDevice]);

    return (
        <>
            {isMobileDevice ? <HighlightsMobile /> : <HighlightDesktop />}
        </>
    )
}

export default Highlights
