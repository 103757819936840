import React from "react"

import styles from "../../styles/contact.module.scss"

import audioIcon from "../../images/audioIcon.svg"
import whatsappIcon from "../../images/whatsappIcon.svg"
import mailIcon from "../../images/mailIcon.svg"
import phoneIcon from "../../images/phoneIcon.svg"

import Icon from "../Icon/icon"

const RedirectForMobile = () => {
  return "tel:48991289058"
}

function experienceContact() {
  return (
    <section className={styles.experienceContainer}>
      <div className={styles.experienceContent}>
        <p>um atendimento digital de verdade</p>
        <p>fala de experiência, de dados</p>
        <p>e de gente. igual a gente.</p>
      </div>
      <div className={styles.contactContent}>
        <p> Fale com a gente</p>
        <div className={styles.contactWrapper}>
          <a
            href="https://wa.me/5548991289058"
            target="_blank"
            className={styles.audioWrapper}
          >
            <Icon style={styles.audioIcon} imageSrc={audioIcon} />
            <span>Áudio</span>
          </a>
          <a
            href="https://wa.me/5548991289058"
            target="_blank"
            className={styles.whatsappWrapper}
          >
            <Icon style={styles.whatsappIcon} imageSrc={whatsappIcon} />
            <span>WhatsApp</span>
          </a>
          <a
            href="mailto:joseph@pessoalize.com?subject=Olá Pessoalize"
            className={styles.mailWrapper}
          >
            <Icon style={styles.mailIcon} imageSrc={mailIcon} />
            <span>E-mail</span>
          </a>
          <a href={RedirectForMobile()} className={styles.phoneWrapper}>
            <Icon style={styles.phoneIcon} imageSrc={phoneIcon} />
            <span>(48)9-9128-9058</span>
          </a>
        </div>
      </div>
    </section>
  )
}

export default experienceContact
