import React, { useState } from 'react'
import { CSSTransition } from 'react-transition-group'

import logoIcon from '../../images/logo-icon.svg';
import Icon from '../Icon/icon'

import styles from '../../styles/highlights.module.scss'
import '../../styles/effects.css'

function HighlightsForm({ fixed }) {
    const [isOpen, setIsOpen] = useState(fixed)
    const [iconClass, setIconClass] = useState(styles.highlightIconWrapper)

    function toggleCard() {
        if (fixed) {
            return
        }
        setIsOpen(!isOpen)
    }

    function fixIconPosition() {
        setIconClass(styles.hideIcon)
    }

    function removeIconClass() {
        setIconClass(styles.highlightIconWrapper)
    }

    return (
        <div
            onMouseEnter={() => toggleCard()}
            onMouseLeave={() => toggleCard()}
            className={styles.highlightFormContainer}
        >
            {!isOpen ? (
                <>
                    <div className={iconClass}>
                        <Icon imageSrc={logoIcon} />
                        <Icon imageSrc={logoIcon} />
                    </div>
                </>
            ) : null}
            <CSSTransition
                in={isOpen}
                timeout={200}
                mountOnEnter
                unmountOnExit
                classNames='node'
                onExiting={() => fixIconPosition()}
                onExited={() => removeIconClass()}
            >
                <div className={styles.highlightForms}>
                    <div className={styles.highlightFormsWrapper}>
                        <div className={styles.highlightFormsContent}>
                            <p>
                                <span><strong>Ferramenta digital</strong></span> é produto de prateleira perto do que fazemos.
                                <strong> Revolucionamos a experiência digital entre <span className={styles.crossed}>marcas e clientes.</span> </strong>
                                <strong><span>Pessoas e pessoas.</span></strong>
                            </p>
                        </div>
                        <div className={styles.highlightFormsInput}>
                            <form>
                                <input type="text" placeholder="Fala pra gente: o que vc precisa?" />
                            </form>
                            <span></span>
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </div>
    )
}

export default HighlightsForm
