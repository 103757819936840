import React, { useEffect } from "react"

import Layout from "../components/Layout/layout"
import SEO from "../components/SEO/seo"
import Highlights from '../components/Highlights/highlights'
import AnimationZ from "../components/AnimationZ/animationZ"
import ExperienceContact from "../components/ExperienceContact/experienceContact"
import Results from "../components/Results/results"
import Revolution from "../components/Revolution/revolution"
import Lamp from "../components/Lamp/lamp"

const HomePage = () => (



  <Layout>
    <SEO title="Home" />
    <Highlights />
    <AnimationZ />
    <Lamp />
    <Results />
    <Revolution />
    <ExperienceContact />
  </Layout>
)

export default HomePage
