import React, { useRef, useContext } from 'react'

import { AppContext } from '../../../provider'
import useParallax from '../../hooks/useParallax'

import revolutionTopMobile from '../../images/revolution-top-mobile.svg'
import revolutionBottomMobile from '../../images/revolution-bottom-mobile.svg'

import '../../styles/parallax.scss'
import styles from '../../styles/pages.module.scss'

function Revolution() {
    const { isMobileDevice } = useContext(AppContext) || {}
    const element = useRef(null)

    function handleScroll(element) {
        return element.current?.getBoundingClientRect().top
    }

    const offsetTop = useParallax(function () {
        return handleScroll(element)
    })

    function revolutionMobile() {
        return (
            <>
                <div className={`${styles.revolutionWrapper} ${styles.top}`}>
                    <div className={styles.revolutionImg}>
                        <img
                            src={revolutionTopMobile} className={`${styles.revolutionImgMobile} ${styles.imgTop}`}
                            alt='revolution-animation'
                        />
                    </div>
                </div>
                <div className={`${styles.revolutionWrapper} ${styles.bottom}`}>
                    <div className={styles.revolutionImg}>
                        <img
                            src={revolutionBottomMobile} className={`${styles.revolutionImgMobile} ${styles.imgBottom}`}
                            alt='revolution-animation'
                        />
                    </div>
                </div>
            </>
        )
    }

    function revolutionDesktop() {
        return (
            <>
                <div className="parallax-revolution">
                    <div ref={element} className={`${styles.revolutionWrapper} purple`} style={{ backgroundPosition: `50% ${offsetTop * 0.45}px` }}>
                        <div className={`${styles.revolutionImg} `} />
                    </div>
                    <div className={`${styles.revolutionWrapper} aqua`} style={{ backgroundPosition: `50% ${-225 + offsetTop * 0.45}px` }}>
                        <div className={`${styles.revolutionImg} `} />
                    </div>
                </div>
            </>)
    }

    return (
        <section className={`${styles.pageContainer} ${styles.revolution}`}>
            {
                isMobileDevice !== undefined && isMobileDevice ? revolutionMobile() : revolutionDesktop()
            }
        </section>
    )
}

export default Revolution
