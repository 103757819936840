import React, { useRef, useContext, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Image from 'gatsby-image'

import { AppContext } from '../../../provider'
import useComponentVisible from '../../hooks/useComponentVisible'
import SliderCarousel from '../SliderCarousel/sliderCarousel';

import styles from '../../styles/pages.module.scss'
import '../../styles/carousel.scss'

function AnimationZ () {
    const { desktop, mobile } = useStaticQuery(
        graphql`
          query {
            desktop: file(relativePath: { eq: "p-image.png" }) {
              childImageSharp {
                fluid(quality: 70, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            mobile: file(relativePath: { eq: "pz-mobile.png" }) {
              childImageSharp {
                fluid(quality: 70, maxWidth: 768) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        `
    )
    const { isMobileDevice } = useContext(AppContext) || {}
    const imageData = isMobileDevice !== undefined && isMobileDevice ? mobile.childImageSharp.fluid : desktop.childImageSharp.fluid
    const imageClass = isMobileDevice !== undefined && isMobileDevice ? styles.pImageMobile : styles.pImageDesktop
    const imgContainer = useRef(null)
    const [lineClasses, setLineClasses] = useState({
        first: styles.lineOneTwo,
        second: styles.lineTwoThree,
        third: styles.lineThreeFour
    })

    const responsive = {
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const { first, second, third } = lineClasses

    function handleAnimationDelay() {
        setLineClasses({
            first: `${styles.lineOneTwo} ${styles.animateVertical}`,
            second: `${styles.lineTwoThree} ${styles.animateDiagonal}`,
            third: `${styles.lineThreeFour} ${styles.animateVertical}`
        })
    }

    useComponentVisible(imgContainer, handleAnimationDelay)

    return (
        <section className={styles.pageContainer} >
            <div className={styles.pageTitle}>
                <div className={styles.pageTitleWrapper}>
                    <h2>Vínculos inéditos a cada interação entre a sua empresa e o seu cliente.</h2>
                </div>
            </div>
            <div className={styles.pageContent}>
                <div className={styles.pageContentWrapper}>
                    <div className={styles.pageLeft}>
                        <div className={styles.pageTextContainer} >
                            <p className={styles.left}>Criamos um time</p>
                            <p className={`${styles.left} ${styles.numberOne}`}>exclusivo para</p>
                            <p className={styles.left}>sua empresa</p>
                        </div>
                        <div className={styles.pageTextContainer}>
                            <p className={styles.left}>Usamos tecnologia</p>
                            <p className={`${styles.left} ${styles.numberThree}`}>para começar</p>
                            <p className={styles.left}>conversas</p>
                        </div>
                    </div>
                    <div ref={imgContainer} className={styles.zContainer}>
                        <div className={styles.mainImage}>
                            <Image
                                className={imageClass}
                                fluid={imageData}
                                id="animationZBg"
                                role="img"
                                aria-label="Animation Background"
                            />
                        </div>
                        <div className={styles.zWrapper}>
                            <span className={first}></span>
                            <span className={second}></span>
                            <span className={third}></span>
                        </div>
                    </div>
                    <div className={styles.pageRight}>
                        <div className={styles.pageTextContainer}>
                            <p className={styles.right}>Analisamos dados</p>
                            <p className={`${styles.right} ${styles.numberTwo}`}>e desenhamos</p>
                            <p className={styles.right}>a sua jornada digital</p>
                        </div>
                        <div className={styles.pageTextContainer}>
                            <p className={styles.right}>Interagimos com</p>
                            <p className={`${styles.right} ${styles.numberFour}`}>pessoas em cada</p>
                            <p className={styles.right}>uma delas</p>
                        </div>
                    </div>
                </div>
            </div>
            {
                isMobileDevice ?
                    (
                        <SliderCarousel
                            responsive={responsive}
                            showDots={true}
                            renderDotsOutside={true}
                            swipeable={true}
                            arrows={false}
                            dotListClass={'carouselNumbers'}
                        >
                            <div className={styles.carouselNumbersContent}>
                                <p>Criamos um time</p>
                                <p>exclusivo para</p>
                                <p>sua empresa</p>
                            </div>
                            <div className={styles.carouselNumbersContent}>
                                <p>Usamos tecnologia</p>
                                <p>para começar</p>
                                <p>conversas</p>
                            </div>
                            <div className={styles.carouselNumbersContent}>
                                <p>Analisamos dados</p>
                                <p>e desenhamos</p>
                                <p>a sua jornada digital</p>
                            </div>
                            <div className={styles.carouselNumbersContent}>
                                <p>Interagimos com</p>
                                <p>pessoas em cada</p>
                                <p>uma delas</p>
                            </div>
                        </SliderCarousel>
                    ) : null
            }
        </section>
    )
}

export default AnimationZ
