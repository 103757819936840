import React from 'react'

import HighlightBackground from './highlightBackground'
import HighlightCard from './highlightCard'
import SliderCarousel from '../SliderCarousel/sliderCarousel'
import HighlightsForm from './highlightsForm'

import styles from '../../styles/highlights.module.scss'

import logo from '../../images/logopurple.svg'
import text2 from '../../images/texts/text2.svg'
import text1 from '../../images/texts/text1.svg'
import text3 from '../../images/texts/text3.svg'
import text4 from '../../images/texts/text4.svg'
import text5 from '../../images/texts/text5.svg'
import text6 from '../../images/texts/text6.svg'

function HighlightsMobile () {
    const responsive = {
        tablet: {
          breakpoint: { max: 1025, min: 768 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 767, min: 0 },
          items: 1
        }
    };
    return (
        <>
            <section>
                <HighlightBackground className={'parallax-background'} />
                <div className={styles.highlights}>
                    <div className={styles.highlightsContainer}>
                        <div className={styles.highlightsWrapper}>
                            <HighlightCard customClass={styles.highlightsLogoCard} fixed={true}>
                                <div className={styles.highlightContent}>
                                    <div className={`${styles.highlightCardContainer} ${styles.cardAqua}`}>
                                        <img src={logo} alt='card-logo' />
                                    </div>
                                </div>
                            </HighlightCard>
                        </div>
                    </div>
                    <div className={styles.highlightsWrapper}>
                        <HighlightCard customClass={styles.highlightsMainInfo}>
                            <div className={styles.highlightContent}>
                                <div className={`${styles.highlightCardContainer} ${styles.cardPurple} ${styles.strategy}`}>
                                    <img src={text2} alt='card-serviços' />
                                </div>
                            </div>
                        </HighlightCard>
                    </div>
                </div>
            </section>
            <section>
                <SliderCarousel
                    responsive={responsive}
                    showDots={true}
                    renderDotsOutside={true}
                    swipeable={true}
                    arrows={false}
                    dotListClass={'highlights-dots'}
                >
                    <HighlightCard fixed={true}>
                        <div className={styles.highlightContent}>
                            <div className={`${styles.highlightCardContainer} ${styles.cardBlue}`}>
                                <img src={text1} alt='card-serviços' />
                            </div>
                        </div>
                    </HighlightCard>
                        <HighlightCard fixed={true}>
                            <div className={styles.highlightContent}>
                                <div className={`${styles.highlightCardContainer} ${styles.cardPink}`}>
                                    <img src={text3} alt='card-serviços'/>
                                </div>
                            </div>
                        </HighlightCard>
                        <HighlightCard fixed={true}>
                        <div className={styles.highlightContent}>
                            <div className={`${styles.highlightCardContainer} ${styles.cardPurple} ${styles.libra}`}>
                                <img src={text4} alt='card-acessibilidade' />
                            </div>
                        </div>
                        </HighlightCard>
                        <HighlightCard fixed={true}>
                            <div className={styles.highlightContent}>
                                <div className={`${styles.highlightCardContainer} ${styles.cardAqua}`}>
                                    <img src={text5} alt='card-serviços' />
                                </div>
                            </div>
                        </HighlightCard>
                        <HighlightCard fixed={true}>
                            <div className={styles.highlightContent}>
                                <div className={`${styles.highlightCardContainer} ${styles.cardPurple} ${styles.reclame}`}>
                                    <img src={text6} alt='card-reclameaqui'/>
                                </div>
                            </div>
                        </HighlightCard>
                </SliderCarousel>
                <div className={styles.highlightsAudioInputContainer}>
                    <div className={styles.highlightsAudioInputWrapper}>
                        <HighlightsForm fixed={true} />
                    </div>
                </div>
            </section>
        </>
    )
}

export default HighlightsMobile;
