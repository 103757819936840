import React, { useEffect, useRef } from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

function SliderCarousel(props) {
    let firstClientX, clientX;
    let containerRef = useRef();

    function preventTouch(e) {
        const minValue = 5;

        clientX = e.touches[0].clientX - firstClientX;

        if (Math.abs(clientX) > minValue) {
            e.preventDefault()
            e.returnValue = false

            return false
        }
    }

    function touchStart(e) {
        firstClientX = e.touches[0].clientX;
    }

    useEffect(() => {
        const containerEl = containerRef.current
        if (containerEl) {
            containerEl.addEventListener("touchstart", touchStart);
            containerEl.addEventListener("touchmove", preventTouch, {
                passive: false
            });
        }

        return () => {
            if (containerEl) {
                containerEl.removeEventListener("touchstart", touchStart);
                containerEl.removeEventListener("touchmove", preventTouch, {
                    passive: false
                });
            }
        };
    });

    return (
        <div ref={containerRef}>
            <Carousel {...props} ref={props.fowardedRef}>
                {props.children}
            </Carousel>
        </div>
    )
}

export default SliderCarousel;
