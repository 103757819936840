import React, { useContext } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { AppContext } from '../../../provider'
import Image from 'gatsby-image'

function HighlightBackground ({ className, offset }) {
  const { desktop, mobile } = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "background-parallax-medium-reduced.png" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        mobile: file(relativePath: { eq: "bg-mobile.png" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 768) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )

  const { isMobileDevice } = useContext(AppContext) || {}
  const imageData = isMobileDevice !== undefined && isMobileDevice ? mobile.childImageSharp.fluid : desktop.childImageSharp.fluid

  return (
    <Image
      style={{ transform: `translateY(-${offset * 0.2}px)` }}
      className={className}
      fluid={imageData}
      id="fullscreenbg"
      role="img"
      aria-label="Fullscreen Background"
    />
  )
}

export default HighlightBackground
